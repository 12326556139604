<template>
  <b-tabs
    class="mt-25"
    :nav-class="isMobileView ? 'mb-50' : ''"
  >
    <!-- SECTION Fare & Benefits -->
    <b-tab
      v-if="trip.fareOptions.length"
      :title-link-class="isMobileView ? 'px-25 mr-75' : ''"
    >
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>{{ $t('flight.fare&benefit') }}</span>
      </template>

      <!-- ANCHOR - NOTE Liên Hệ -->
      <b-alert
        v-if="trip && (trip.source === '1G' || !trip.domestic)"
        class="mb-0 mx-50 mx-md-1 rounded-lg"
        style="background-color: #F5F5F5 !important;"
        show
      >
        <div
          class="alert-body d-flex align-items-center"
          style="color: #EF5350; font-weight: 700;"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
            :size=" isMobileView ? '18': '20'"
          />
          <span>
            Vui lòng kiểm tra lại với Booker về điều kiện vé và hành lý
          </span>
        </div>
      </b-alert>

      <!-- NOTE list group -->
      <b-list-group
        class="cursor-pointer m-md-1 m-50"
        flush
      >
        <div
          v-for="(fareItem, i) in trip.fareOptions"
          :key="fareItem.id"
        >
          <b-list-group-item
            v-show="isRenderFareArray.length ? isRenderFareArray[i] : true"
            :class="i !== (trip.fareOptions.length - 1) ? 'mb-50' : ''"
            class="border py-50 px-1"
            :active="ticketSelected === fareItem.id && selectedTrip && selectedTrip.id === trip.id"
            @click="handleClickTicket(fareItem)"
          >
            <!-- SECTION Giá vé & quyền lợi -->
            <b-row
              class="mx-0"
              no-gutters
            >
              <!-- ANCHOR Tên hạng vé, fareCode  -->
              <b-col
                cols="12"
                lg="5"
              >
                <div
                  :id="`class-booking-fare-name-${trip.id}-${fareItem.id}-${i}`"
                  class="text-truncate"
                >
                  <span
                    v-if="!['VJ'].includes(trip.source)
                      && fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source)
                        ? fareItem.fareType
                        : fareItem.groupClass
                      )"
                  >
                    {{
                      fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source)
                        ? fareItem.fareType
                        : fareItem.groupClass).cabinName
                    }}
                  </span>

                  <span>
                    {{
                      ['QH', 'VU', '1S'].includes(trip.source)
                        ? '' : `${(fareItem.groupClass)}`
                    }}
                    <span v-if="getShowBookingClass">
                      (
                      {{ fareItem.bookingClass }}
                      <small
                        style="color: #E2365D !important;"
                        class="h6"
                      >
                        {{ fareItem.fareBasisCode }}
                      </small>
                      )
                    </span>
                  </span>

                  <b-tooltip
                    :id="`tooltip-class-booking-fare-name-${trip.id}-${fareItem.id}-${i}`"
                    triggers="hover"
                    variant="info"
                    placement="top"
                    boundary="window"
                    :target="`class-booking-fare-name-${trip.id}-${fareItem.id}-${i}`"
                  >
                    <div class="text-heading-5 text-white font-weight-bolder">
                      <div v-if="trip.source !== 'VJ' && fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass)">
                        {{
                          fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source)
                            ? fareItem.fareType
                            : fareItem.groupClass).cabinName
                        }}
                      </div>
                      <div>
                        {{
                          ['QH', 'VU', '1S'].includes(trip.source)
                            ? '' : `${(fareItem.groupClass)}`
                        }}
                        <span v-if="getShowBookingClass">
                          (
                          {{ fareItem.bookingClass }}
                          <span class="h5 text-white">
                            {{ fareItem.fareBasisCode }}
                          </span>
                          )
                        </span>
                      </div>
                    </div>
                  </b-tooltip>
                </div>

                <!-- ANCHOR - Số chỗ (show: lg,xl) -->
                <!-- <div class="d-none d-lg-block">
                  <b-img
                    class="mr-25"
                    blank-color="#ccc"
                    style="height: 24px;"
                    :src="require(`@/assets/other-icon/seat.svg`)"
                  />
                  <span class="text-nowrap">
                    {{ $t('flight.seatAvailable') }}:
                    <b class="text-primary font-weight-bold">
                      {{
                        fareItem.seatAvailable
                      }}
                    </b>
                  </span>
                </div> -->
              </b-col>
              <!-- ANCHOR Chi tiết giá theo Pax (fare,net,total) -->
              <b-col
                cols="12"
                lg="7"
                class="my-75 my-md-0"
              >
                <div
                  v-for="(priceItem, indexPrice) in resolveFarePrice(flightSearch, fareItem)"
                  :key="indexPrice"
                >
                  <b-row
                    v-if="isVisiblePriceFareCol && priceItem.quantity"
                    class="justify-content-between mx-0"
                  >
                    <b-col
                      cols="auto"
                      md="3"
                      lg="2"
                      xl="3"
                      class="text-right px-0"
                    >
                      <span>{{ priceItem.title }}</span>
                      <!-- <span> x {{ priceItem.quantity }}</span> -->
                    </b-col>
                    <b-col
                      v-if="priceItem.title === 'INF' && (['AK'].includes(trip.source) || (['TH'].includes(trip.source) && priceItem.fare === 0))"
                      cols="auto"
                      md="9"
                      lg="10"
                      xl="9"
                      class="text-danger font-weight-bolder text-right px-0"
                    >
                      Vui lòng vào màn nhập thông tin để kiểm tra giá em bé
                    </b-col>

                    <!-- ANCHOR: GIÁ VÉ -->
                    <b-col
                      v-if="isVisiblePriceFareCol && !(priceItem.title === 'INF' && (['AK'].includes(trip.source) || (['TH'].includes(trip.source) && priceItem.fare === 0)))"
                      cols="auto"
                      md="3"
                      class="text-right px-50 px-lg-0 d-flex flex-column"
                      :class="isMobileView ? '' : ''"
                    >
                      <span
                        v-b-tooltip.hover.html.v-primary
                        class="text-nowrap"
                        :title="`${$t('flight.netFare')}: ${formatCurrency(priceItem.fare)}
                                ${getShowPriceAfterDiscount ? `<br>${$t('flight.promotion')}: ${formatCurrency(priceItem.promotion)}<br>
                                ${trip.source === '1G'? $t('flight.contactBookerForDiscount') : `${$t('flight.discountAirline')}: ${formatCurrency(priceItem.discount)}`}` : ''}
                                `"
                      >
                        {{ formatCurrency(priceItem.priceFareCol) }}
                        <span
                          v-if="priceItem.priceDiscountFareCol > 0"
                          class="badge badge-up badge-pill bg-danger badge-glow text-nowrap discount-badge"
                        >
                          {{
                            `${numberToFixed(((priceItem.priceDiscountFareCol) / priceItem.fare) * 100)}%`
                          }}
                        </span>
                      </span>

                      <del
                        v-if="(priceItem.discount || priceItem.promotion) && getShowPriceAfterDiscount"
                        class="text-secondary"
                        :class="isMobileView ? 'font-small-2' : 'font-small-4'"
                      >
                        {{ formatCurrency(priceItem.fare) }}
                      </del>
                    </b-col>

                    <!-- ANCHOR: cột thuế phí -->
                    <b-col
                      v-if="isVisibleFeeCol && !(priceItem.title === 'INF' && (['AK'].includes(trip.source) || (['TH'].includes(trip.source) && priceItem.fare === 0)))"
                      cols="auto"
                      md="3"
                      class="text-right px-0"
                      :class="isMobileView ? '' : ''"
                    >
                      <span
                        v-b-tooltip.hover.html.v-primary
                        class="text-nowrap"
                        :title="`${$t('flight.taxes')}: ${formatCurrency(priceItem.tax + priceItem.surcharge + (!getShowServiceFee && getShowPrice !== 'NET_FARE' ? priceItem.serviceFee : 0))}
                                ${getShowServiceFee ? `<br>${$t('flight.serviceCharge')}: ${formatCurrency(priceItem.serviceFee)}` : ''}
                                ${getShowAgencyFee && priceItem.title !== 'INF' ? `<br>${$t('flight.agencyFee')}: ${formatCurrency(priceItem.agencyFee)}` : ''}
                                `"
                      >
                        {{ formatCurrency(priceItem.priceFeeCol) }}
                      </span>
                    </b-col>

                    <!-- ANCHOR TỔNG TIỀN -->
                    <b-col
                      v-if="isVisibleTotalFareCol && !(priceItem.title === 'INF' && (['AK'].includes(trip.source) || (['TH'].includes(trip.source) && priceItem.fare === 0)))"
                      cols="auto"
                      md="3"
                      class="text-right px-0 d-flex flex-column"
                      :class="isMobileView ? '' : ''"
                    >
                      <HStack
                        v-b-tooltip.hover.v-primary
                        end
                        :class="`text-airline text-nowrap ${priceItem.discount ? 'text-success' : ''}`"
                        :title="$t('flight.Total')"
                      >
                        {{ formatCurrency(priceItem.priceTotalCol) }}

                        <BSpinner
                          v-if="loadingCalculatePrice && ticketSelected === fareItem.id"
                          v-b-tooltip.hover.html.v-dark="'Đang tính lại giá'"
                          variant="primary"
                          small
                        />
                      </HStack>
                      <del
                        v-if="(priceItem.discount || priceItem.promotion) && getShowPriceAfterDiscount"
                        class="text-secondary"
                        :class="isMobileView ? 'font-small-2' : ''"
                      >
                        {{ formatCurrency(priceItem.priceTotalCol + priceItem.discount + priceItem.promotion) }}
                      </del>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>

            <FareRulesDetail
              :fare-item="fareItem"
              :trip="trip"
            />

            <!-- ANCHOR - Icon Select -->
            <div
              class="position-absolute rounded-pill"
              :style="`background-color: white; top: 30%; ${isMobileView ? `left: -8px;` : `left: -13px;`}`"
            >
              <feather-icon
                v-if="ticketSelected === fareItem.id && selectedTrip && selectedTrip.id === trip.id"
                icon="CheckCircleIcon"
                :size="isMobileView? '20' : '30'"
                class="text-warning"
              />
              <feather-icon
                v-else
                icon="CircleIcon"
                :size="isMobileView? '20' : '30'"
                class="text-secondary"
              />
            </div>
            <!-- !SECTION -->
          </b-list-group-item>
        </div>
      </b-list-group>

      <b-alert
        v-if="isShowAlertContactBookerToCheckPrice1G(trip)"
        show
        variant="warning"
        class="py-50 px-1 mb-0 text-center"
      >
        Vui lòng liên hệ Booker để kiểm tra lại giá vé chuyến bay này!
      </b-alert>
    </b-tab>
    <!-- !SECTION -->

    <!-- SECTION Flight Detail -->
    <b-tab :title-link-class="isMobileView ? 'px-25' : ''">
      <template #title>
        <feather-icon icon="AlertCircleIcon" />
        <span>{{ $t('flight.flightDetails') }}</span>
      </template>

      <div
        v-for="(segment, indexSegment) in trip.segments"
        :key="indexSegment"
      >
        <div v-if="segment.flightLegInfoList && segment.flightLegInfoList.length > 0">
          <FlightDetail
            v-for="(seg, indexSeg) in segment.flightLegInfoList"
            :key="indexSeg"
            :class="indexSeg !== 0 ? 'border-top' : ''"
            :segment="{ ...segment, ...seg }"
            :trip="trip"
            :index="index"
            :index-segment="indexSegment"
            :operating="segment.operating"
            :airline="segment.airline"
            :flight-number="segment.flightNumber"
            :selected-trip="selectedTrip?.id === trip.id ? selectedTrip : null"
          />
        </div>

        <FlightDetail
          v-else
          :segment="segment"
          :trip="trip"
          :index="index"
          :index-segment="indexSegment"
          :operating="segment.operating"
          :airline="segment.airline"
          :flight-number="segment.flightNumber"
          :selected-trip="selectedTrip?.id === trip.id ? selectedTrip : null"
        />

        <!-- SECTION Giữa chặng -->
        <div v-if="indexSegment !== trip.segments.length - 1">
          <div v-if="segment.isNextAirport">
            <b-alert
              show
              variant="warning"
              class="py-50 px-1 shadow-sm d-flex align-items-center"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                size="21"
                class="text-warning mr-1"
              />
              <b>{{ $t('flight.alertNextAirport') }}</b>
            </b-alert>
          </div>
          <div v-if="segment.isNextDay">
            <b-alert
              show
              variant="warning"
              class="py-50 px-1 shadow-sm d-flex align-items-center"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                size="21"
                class="text-warning mr-1"
              />
              <b>{{ $t('flight.alertNextDay') }}</b>
            </b-alert>
          </div>
          <div v-if="segment.transitTime">
            <b-alert
              show
              variant="primary"
              class="py-50 px-1 shadow-sm d-flex align-items-center"
            >
              <feather-icon
                icon="InfoIcon"
                size="21"
                class="text-primary mr-1"
              />
              <b>{{ $t('ticket.flightsInfo.transitTime') }}: {{ segment.transitTime }}</b>
            </b-alert>
          </div>
          <b-alert
            show
            variant="primary"
            class="py-50 px-1 shadow-sm d-flex align-items-center"
          >
            <feather-icon
              icon="InfoIcon"
              size="21"
              class="text-primary mr-1"
            />
            <b>{{ $t('flight.transitFlightInfo') }}</b>
          </b-alert>
        </div>
      </div>
    </b-tab>
    <!-- !SECTION -->

    <!-- SECTION TAB-END: Button kiểm tra visa- nhập cảnh -->
    <template #tabs-end>
      <div class="flex-fill d-flex-center justify-content-end mr-25 mr-md-1">
        <div v-if="!trip.domestic">
          <b-button
            :id="`button-retrieve-visa-${trip.id}`"
            variant="outline-primary"
            class="py-25 px-1 mr-md-1 rounded-lg mt-25"
          >
            <div class="d-flex-center gap-1">
              <IAmIcon
                icon="airportSecurity"
                size="20px"
              />
              <small>{{ $t('flight.checkVisa') }}</small>
            </div>
          </b-button>
          <b-popover
            :target="`button-retrieve-visa-${trip.id}`"
            triggers="click"
            placement="top"
            class="rounded p-25"
          >
            <div class="d-flex-center">
              <div class="text-nowrap">
                Chọn quốc tịch:
              </div>
              <v-select
                v-model="nationalityChoose"
                class="mx-25"
                :options="countriesList"
                :reduce="(val) => val"
                :clearable="false"
                label="id"
                :placeholder="$t('placeholderSelect')"
                searchable
                style="width: 90px;"
                :filterable="false"
                @open="openGetCountries"
                @search="searchGetCountries"
              >
                <template #selected-option="data">
                  {{ data.shortCode }}
                </template>
                <template #option="data">
                  <span class="font-weight-bolder">({{ data.shortCode }})</span> {{ data.countryName }}
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
              <b-button
                variant="flat-primary"
                class="p-50 rounded-circle"
                @click.prevent.stop="handleRetrieveVisa(trip ,`button-retrieve-visa-${trip.id}`)"
              >
                <feather-icon
                  size="18"
                  icon="ArrowRightIcon"
                />
              </b-button>
            </div>
          </b-popover>
        </div>
      </div>
    </template>
    <!-- !SECTION -->
  </b-tabs>
</template>

<script>
import {
  BButton,
  VBToggle,
  BAlert,
  VBTooltip,
  BTooltip,
  BListGroup,
  BListGroupItem,
  BCol,
  BRow,
  BTabs,
  BTab,
  BPopover,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, computed, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import vSelect from 'vue-select'
import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'

import { BUSINESS_FILTER_REGEX, SKYBOSS_FILTER_REGEX, BUSINESS_SKYBOSS_FILTER_REGEX } from '@/constants/flight'
import store from '@/store'
import { numberToFixed } from '@/views/invoices/useInvoiceHandle'

import {
  convertISODateTime,
  toHoursAndMinutes,
  formatCurrency,
  checkTransitAlert,
} from '@core/utils/filter'

import useBookingHandle, { useCalculatePriceVna } from '@flightv2/useBookingHandle'

export default {
  components: {
    BTooltip,
    BButton,
    BAlert,
    BListGroup,
    BListGroupItem,
    BCol,
    BRow,
    BTabs,
    BTab,
    BPopover,
    BSpinner,
    vSelect,

    FlightDetail: () => import('@/components/flight-detail/FlightDetail.vue'),
    FareRulesDetail: () => import('./FareRulesDetail.vue'),
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },

  props: {
    trip: {
      type: Object,
      default: () => { },
    },
    flightSearch: {
      type: Object,
      default: () => { },
    },
    selectedTrip: {
      type: Object,
      default: () => { },
    },
    index: {
      type: Number,
      required: true,
    },
    indexItinerary: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      convertISODateTime,
      toHoursAndMinutes,
      farePrice: 0,
    }
  },
  computed: {
    tabIndex() {
      return this.$store.getters['app-flight-v2/getTabIndex']
    },
    selectedFlightSegment() {
      return this.$store.getters['app-flight-v2/getSelectedFlightSegment']
    },
    itineraryLength() {
      return this.$store.getters['app-flight-v2/getDataSearchItineraryLength']
    },
  },

  created() {
    if (this.trip.segments.length > 0) {
      this.checkAlertSegments(this.trip)
    }
  },
  mounted() {
    this.$bus.$on('update-selected', ({ fareId, indexTrip }) => {
      if (indexTrip === this.index) {
        this.ticketSelected = fareId
      }
    })
  },
  methods: {
    checkAlertSegments(data) {
      for (let i = 0; i < data.segments.length - 1; i += 1) {
        const segment = data.segments[i]
        const nextSegment = data.segments[i + 1]
        const { isNextAirport, isNextDay, transitTime } = checkTransitAlert(segment.arrival, nextSegment.departure)
        Object.assign(segment, {
          isNextAirport, isNextDay, transitTime,
        })
      }
    },
    fareRulesAirline(airline, cabin) {
      return this.$store.getters['globalConfig/getFareRulesAirline'](airline, cabin)
    },
  },
  setup(props, { emit, root }) {
    const ticketSelected = ref()

    const {
      airlineRoundtrip,
      retrieveVisa,
      countriesList,
      openGetCountries,
      searchGetCountries,
      getSearchFlight,
      checkDeniedGroupFare,
      getSelectedSourceName,
    } = useBookingHandle()

    const { calculatePriceVNA, loadingCalculatePrice, isAutoCalculatePriceForVNASearch } = useCalculatePriceVna()
    const draftSelectedTrip = computed(() => store.getters['app-flight-v2/getDraftSelectedTrip'])

    async function handleClickTicket(selectedFareOpt) {
      const fareId = selectedFareOpt.id
      const source = props.trip?.source
      const isValidSearchType = getSearchFlight.value?.type !== 'OW'

      const isDeniedRoundTripTAP = isValidSearchType
        && (props.selectedTrip?.fareOptions?.id !== fareId) && !isEmpty(draftSelectedTrip.value.filter(Boolean))
        && checkDeniedGroupFare(draftSelectedTrip.value, props.indexItinerary, props.trip.isGroupedItineraryResponse, source)

      const selectedSource = draftSelectedTrip.value.flat().filter(Boolean)?.[0]?.source

      if (isDeniedRoundTripTAP) {
        Vue.swal({
          title: this.$t('Notification'),
          text: this.$t('flight.invalidSelectGroupFare', { source: getSelectedSourceName(selectedSource, source) }),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        })
      } else {
        const selected = {
          ...props.trip,
          fareOptions: selectedFareOpt,
        }

        if (props.selectedTrip?.fareOptions?.id === fareId) {
          ticketSelected.value = null
          emit('update:selectedTrip', null)
          if ((airlineRoundtrip.value || selected?.source === '1G') && getSearchFlight.value?.type === 'RT') {
            store.dispatch('app-flight-v2/setAirlineRoundtrip', null)
            store.dispatch('app-flight-v2/setCombinationKeys', null)
            emit('clear') // clearSelectedTrip
          }
        } else {
          const regexFareDiscount = /^[A-Z0-9]AP[A-Z0-9]*$/
          const fareBasic = ['VN1A'].includes(source) ? (selectedFareOpt?.fareBasisCode || '') : ''

          if (!isEmpty(fareBasic) && regexFareDiscount.test(fareBasic) && isValidSearchType) {
            Vue.swal({
              title: root.$t('Notification'),
              text: 'Hệ thống phát hiện bạn chọn chuyến bay giá Khuyến mãi, giá có thể sai, bạn có muốn tính lại giá không ?',
              icon: '',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Tính giá!',
              customClass: {
                confirmButton: 'btn btn-warning',
                cancelButton: 'btn btn-outline-secondary ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                root.$bvModal.show('calculate-trip-price-modal-vn1a')
              }
            })
          }

          ticketSelected.value = fareId
          emit('update:selectedTrip', cloneDeep(selected))

          if (!selected.domestic && getSearchFlight.value?.type === 'RT') {
            store.dispatch('app-flight-v2/setAirlineRoundtrip', selected.source)
            store.dispatch('app-flight-v2/setCombinationKeys', selected.fareOptions.combinationKeys)
          }
          if (selected.source === 'VN1A' && !['INTERNAL'].includes(selected.clientId) && isAutoCalculatePriceForVNASearch.value) {
            const selectedTripToCalculate = cloneDeep(draftSelectedTrip.value)
            selectedTripToCalculate[props.indexItinerary] = selected
            // Tính giá theo hành trình đã chọn
            const newSelected = await calculatePriceVNA(selectedTripToCalculate, props.indexItinerary)
            emit('update:selectedTrip', cloneDeep(newSelected))
          }
        }
      }
    }

    const pricesFilter = computed(() => store.getters['app-flight-v2/getFilterByPrices'])
    const fareTypeBusinessFilter = computed(() => store.getters['app-flight-v2/getFilterByFareTypeBusiness'])
    const fareTypeSkybossFilter = computed(() => store.getters['app-flight-v2/getFilterByFareTypeSkyboss'])

    const isRenderFareArray = ref([])

    watch([pricesFilter, fareTypeBusinessFilter, fareTypeSkybossFilter], () => {
      try {
        isRenderFareArray.value = props.trip.fareOptions.map(item => {
          const filterRegex = fareTypeBusinessFilter.value ? (fareTypeSkybossFilter.value ? BUSINESS_SKYBOSS_FILTER_REGEX : BUSINESS_FILTER_REGEX) : (fareTypeSkybossFilter.value ? SKYBOSS_FILTER_REGEX : null)
          const isFilterRegex = !filterRegex || (
            (['1S', 'VN1A'].includes(props.trip.source) && item.groupClass.startsWith('B'))
              || (['QH', 'VJ', '1G'].includes(props.trip.source) && filterRegex.test(item.fareType))
          )
          const isFilterPrice = !pricesFilter.value.length
            || (Math.ceil(item.afterMultiple.totalAdultModified) >= pricesFilter.value[0]
            && Math.floor(item.afterMultiple.totalAdultModified) <= pricesFilter.value[1])
          return isFilterRegex && isFilterPrice
        })
      } catch (error) {
        console.log(error)
      } finally {
        store.dispatch('app-flight-v2/setLoading', false)
      }
    }, { deep: true })

    function isShowAlertContactBookerToCheckPrice1G(trip) {
      const carrierAlert = ['JQ', 'CZ', 'MU']
      const x = !carrierAlert.includes(trip.airline)
      const y = trip.segments.some(item => carrierAlert.includes(item.airline))
      return x && y // KIEN
    }

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const nationalityChoose = ref({
      id: 192,
      countryName: 'Vietnam',
      shortCode: 'VN',
      longCode: 'VNM',
    })
    function handleRetrieveVisa(trip, idPopover) {
      this.$root.$emit('bv::hide::popover', idPopover)
      this.$bvModal.show('modal-api-loading')

      const segmentArray = trip.segments.map(segment => segment.arrival.IATACode)
      const arrivals = [segmentArray.pop()]
      if (!nationalityChoose.value || (segmentArray.length === 0 && arrivals.length === 0)) return
      const payload = {
        source: '1G',
        nationality: nationalityChoose.value?.shortCode,
        arrivals,
        transits: segmentArray,
      }
      retrieveVisa(payload)
        .then(res => {
          this.$bvModal.hide('modal-api-loading')

          const titleMsg = () => {
            const messageVNode = res.join('\n').split('\n').map(item => this.$createElement('div', { domProps: { innerHTML: item } }))
            const contentVNode = this.$createElement('div', { class: ['card py-1 px-2 mb-0 text-wrap'], style: 'font-size: 16px; line-height: 24px; background-color: rgb(255, 255, 192); color: rgb(0, 0, 0); font-family: Calibri, sans-serif;' }, [messageVNode])
            return contentVNode
          }
          this.$bvModal
            .msgBoxOk(titleMsg(), {
              title: 'Kiểm tra VISA',
              size: 'lg',
              okVariant: 'secondary',
              okTitle: this.$t('modal.close'),
              hideFooter: true,
              centered: true,
              hideHeaderClose: false,
            })
        })
        .finally(() => { this.$bvModal.hide('modal-api-loading') })
    }

    const getShowBookingClass = computed(() => store.getters['app-flight-v2/getShowBookingClass'])
    const getShowPriceAfterDiscount = computed(() => store.getters['app-flight-v2/getShowPriceAfterDiscount'])
    const getCustomFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](props.trip?.domestic, props.trip?.source))
    const getShowPrice = computed(() => store.getters['app-flight-v2/getShowPrice'])
    const getShowServiceFee = computed(() => store.getters['app-flight-v2/getShowServiceFee'])
    const getShowAgencyFee = computed(() => store.getters['app-flight-v2/getShowAgencyFee'])

    // Visible các cột
    const isVisiblePriceFareCol = computed(() => getShowPrice.value !== 'HIDE_FARE')
    const isVisibleFeeCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))
    const isVisibleTotalFareCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))

    const resolveFarePrice = (flightSearch, fareItem) => {
      // getAgencyFee
      const {
        hideFee, agencyFee,
        hideFeeParent, agencyFeeParent,
        totalFeeAdult, totalFeeChild, totalFeeInfant,
        fareAdultAfterDiscount, fareAdultAfterDiscountAndPromotion,
        fareChildAfterDiscount, fareChildAfterDiscountAndPromotion,
        fareInfantAfterDiscount, fareInfantAfterDiscountAndPromotion,
      } = fareItem

      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (getCustomFeeServiceDataToModify.value?.adultAmount !== null || getCustomFeeServiceDataToModify.value?.childAmount !== null || getCustomFeeServiceDataToModify.value?.infantAmount !== null) {
        modifiedServiceFee.adultAmount = getCustomFeeServiceDataToModify.value?.adultAmount * fareItem.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getCustomFeeServiceDataToModify.value?.childAmount * fareItem.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getCustomFeeServiceDataToModify.value?.infantAmount * fareItem.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? props?.trip?.segments?.length : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      // NOTE: priceFareCol = tiền cột Fare, priceFeeCol = tiền cột phí, !! INF ko tính phí xuất vé
      // Adult
      const priceAdultServiceFee = modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount - fareItem.serviceFeeAdult : 0
      const priceFareColAdult = getShowPriceAfterDiscount.value ? fareAdultAfterDiscountAndPromotion : fareAdultAfterDiscount
      let priceFeeColAdult = totalFeeAdult + priceAdultServiceFee
      // Child
      const priceChildServiceFee = modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount - fareItem.serviceFeeChild : 0
      const priceFareColChild = getShowPriceAfterDiscount.value ? fareChildAfterDiscountAndPromotion : fareChildAfterDiscount
      let priceFeeColChild = totalFeeChild + priceChildServiceFee
      // Infant
      const priceInfantServiceFee = modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount - fareItem.serviceFeeInfant : 0
      const priceFareColInfant = getShowPriceAfterDiscount.value ? fareInfantAfterDiscountAndPromotion : fareInfantAfterDiscount
      let priceFeeColInfant = totalFeeInfant + priceInfantServiceFee

      if (getShowPrice.value === 'NET_FARE') {
        priceFeeColAdult = fareItem.taxAdult + fareItem.surchargeAdult + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0)
        priceFeeColChild = fareItem.taxChild + fareItem.surchargeChild + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0)
        priceFeeColInfant = fareItem.taxInfant + fareItem.surchargeInfant
      }
      const result = [
        {
          title: 'ADT',
          quantity: flightSearch.adult,
          fare: fareItem.fareAdult, // giá vé gốc từ hãng
          agencyFee: hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareItem.surchargeAdult + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0),
          tax: fareItem.taxAdult,
          serviceFee: modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount : fareItem.serviceFeeAdult,
          discount: fareItem.discountAdult,
          promotion: fareItem.promotionAdult,
          priceFareCol: priceFareColAdult,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareItem.discountAdult + fareItem.promotionAdult : 0, // fareItem.discountAdult,
          priceFeeCol: priceFeeColAdult,
          priceTotalCol: priceFareColAdult + priceFeeColAdult,
        },
        {
          title: 'CHD',
          quantity: flightSearch.child,
          fare: fareItem.fareChild, // giá vé gốc từ hãng
          agencyFee: hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareItem.surchargeChild + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0),
          tax: fareItem.taxChild,
          serviceFee: modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount : fareItem.serviceFeeChild,
          discount: fareItem.discountChild,
          promotion: fareItem.promotionChild,
          priceFareCol: priceFareColChild,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareItem.discountChild + fareItem.promotionChild : 0, // fareItem.discountChild,
          priceFeeCol: priceFeeColChild,
          priceTotalCol: priceFareColChild + priceFeeColChild,
        },
        {
          title: 'INF',
          quantity: flightSearch.infant,
          fare: fareItem.fareInfant, // giá vé gốc từ hãng
          agencyFee: 0, // hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareItem.surchargeInfant,
          tax: fareItem.taxInfant,
          serviceFee: modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount : fareItem.serviceFeeInfant,
          discount: fareItem.discountInfant,
          promotion: fareItem.promotionInfant,
          priceFareCol: priceFareColInfant,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareItem.discountInfant + fareItem.promotionInfant : 0, // fareItem.discountInfant,
          priceFeeCol: priceFeeColInfant,
          priceTotalCol: priceFareColInfant + priceFeeColInfant,
        },
      ]
      return result
    }

    function openLinkFareRules(source) {
      const link = store.getters['globalConfig/getLinkFareRules'](source)
      window.open(link)
    }

    return {
      handleClickTicket,
      isRenderFareArray,
      isShowAlertContactBookerToCheckPrice1G,
      isRoleF3,
      handleRetrieveVisa,
      nationalityChoose,
      countriesList,
      openGetCountries,
      searchGetCountries,
      resolveFarePrice,
      getShowPriceAfterDiscount,
      ticketSelected,
      getShowPrice,
      getShowServiceFee,
      getShowAgencyFee,
      isVisiblePriceFareCol,
      isVisibleFeeCol,
      isVisibleTotalFareCol,

      formatCurrency,
      getShowBookingClass,
      openLinkFareRules,
      numberToFixed,
      loadingCalculatePrice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.list-group::v-deep .list-group-item {
  border-radius: $border-radius-lg !important;
  border: 2px solid rgba($secondary, 0.1) !important;

  &.active {
    background-color: rgba($primaryV2, 0.1) !important;
    color: $body-color !important;
    border: 2px solid $primaryV2 !important;
    border-radius: $border-radius-lg !important;

    small {
      color: $body-color !important;
    }

    // &:hover {
    //   background-color: rgba(47, 165, 229, 0.2) !important;
    // }

    // &:focus {
    //   // color: $body-color;
    //   outline: 0;
    //   &:hover {
    //     color: $white;
    //   }
    // }
    // small {
    //   color: $white !important;
    // }
    &:hover {
      background-color: $body-bg;
    }
  }

  &:hover {
    transition: 0.3s !important;
    scale: 1.005 !important;
  }

  &:not(:hover) {
    transition: 0.3s !important;
    transform: scale(1) !important;
  }
}

.discount-badge::v-deep {
  &.badge.badge-up {
    top: -7px;
    right: -22px;
    font-size: 10px;

  // width < md
  @media (max-width: 768px) {
    right: -12px;
    top: -11px;
  }
  }
}
</style>
