var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    staticClass: "mt-25",
    attrs: {
      "nav-class": _vm.isMobileView ? 'mb-50' : ''
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function fn() {
        return [_c('div', {
          staticClass: "flex-fill d-flex-center justify-content-end mr-25 mr-md-1"
        }, [!_vm.trip.domestic ? _c('div', [_c('b-button', {
          staticClass: "py-25 px-1 mr-md-1 rounded-lg mt-25",
          attrs: {
            "id": "button-retrieve-visa-".concat(_vm.trip.id),
            "variant": "outline-primary"
          }
        }, [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "airportSecurity",
            "size": "20px"
          }
        }), _c('small', [_vm._v(_vm._s(_vm.$t('flight.checkVisa')))])], 1)]), _c('b-popover', {
          staticClass: "rounded p-25",
          attrs: {
            "target": "button-retrieve-visa-".concat(_vm.trip.id),
            "triggers": "click",
            "placement": "top"
          }
        }, [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Chọn quốc tịch: ")]), _c('v-select', {
          staticClass: "mx-25",
          staticStyle: {
            "width": "90px"
          },
          attrs: {
            "options": _vm.countriesList,
            "reduce": function reduce(val) {
              return val;
            },
            "clearable": false,
            "label": "id",
            "placeholder": _vm.$t('placeholderSelect'),
            "searchable": "",
            "filterable": false
          },
          on: {
            "open": _vm.openGetCountries,
            "search": _vm.searchGetCountries
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.shortCode) + " ")];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "font-weight-bolder"
              }, [_vm._v("(" + _vm._s(data.shortCode) + ")")]), _vm._v(" " + _vm._s(data.countryName) + " ")];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, false, 3062645965),
          model: {
            value: _vm.nationalityChoose,
            callback: function callback($$v) {
              _vm.nationalityChoose = $$v;
            },
            expression: "nationalityChoose"
          }
        }), _c('b-button', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.handleRetrieveVisa(_vm.trip, "button-retrieve-visa-".concat(_vm.trip.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "18",
            "icon": "ArrowRightIcon"
          }
        })], 1)], 1)])], 1) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_vm.trip.fareOptions.length ? _c('b-tab', {
    attrs: {
      "title-link-class": _vm.isMobileView ? 'px-25 mr-75' : ''
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('flight.fare&benefit')))])];
      },
      proxy: true
    }], null, false, 3466291684)
  }, [_vm.trip && (_vm.trip.source === '1G' || !_vm.trip.domestic) ? _c('b-alert', {
    staticClass: "mb-0 mx-50 mx-md-1 rounded-lg",
    staticStyle: {
      "background-color": "#F5F5F5 !important"
    },
    attrs: {
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body d-flex align-items-center",
    staticStyle: {
      "color": "#EF5350",
      "font-weight": "700"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon",
      "size": _vm.isMobileView ? '18' : '20'
    }
  }), _c('span', [_vm._v(" Vui lòng kiểm tra lại với Booker về điều kiện vé và hành lý ")])], 1)]) : _vm._e(), _c('b-list-group', {
    staticClass: "cursor-pointer m-md-1 m-50",
    attrs: {
      "flush": ""
    }
  }, _vm._l(_vm.trip.fareOptions, function (fareItem, i) {
    return _c('div', {
      key: fareItem.id
    }, [_c('b-list-group-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isRenderFareArray.length ? _vm.isRenderFareArray[i] : true,
        expression: "isRenderFareArray.length ? isRenderFareArray[i] : true"
      }],
      staticClass: "border py-50 px-1",
      class: i !== _vm.trip.fareOptions.length - 1 ? 'mb-50' : '',
      attrs: {
        "active": _vm.ticketSelected === fareItem.id && _vm.selectedTrip && _vm.selectedTrip.id === _vm.trip.id
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTicket(fareItem);
        }
      }
    }, [_c('b-row', {
      staticClass: "mx-0",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('div', {
      staticClass: "text-truncate",
      attrs: {
        "id": "class-booking-fare-name-".concat(_vm.trip.id, "-").concat(fareItem.id, "-").concat(i)
      }
    }, [!['VJ'].includes(_vm.trip.source) && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass) ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).cabinName) + " ")]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(['QH', 'VU', '1S'].includes(_vm.trip.source) ? '' : "".concat(fareItem.groupClass)) + " "), _vm.getShowBookingClass ? _c('span', [_vm._v(" ( " + _vm._s(fareItem.bookingClass) + " "), _c('small', {
      staticClass: "h6",
      staticStyle: {
        "color": "#E2365D !important"
      }
    }, [_vm._v(" " + _vm._s(fareItem.fareBasisCode) + " ")]), _vm._v(" ) ")]) : _vm._e()]), _c('b-tooltip', {
      attrs: {
        "id": "tooltip-class-booking-fare-name-".concat(_vm.trip.id, "-").concat(fareItem.id, "-").concat(i),
        "triggers": "hover",
        "variant": "info",
        "placement": "top",
        "boundary": "window",
        "target": "class-booking-fare-name-".concat(_vm.trip.id, "-").concat(fareItem.id, "-").concat(i)
      }
    }, [_c('div', {
      staticClass: "text-heading-5 text-white font-weight-bolder"
    }, [_vm.trip.source !== 'VJ' && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass) ? _c('div', [_vm._v(" " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).cabinName) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(['QH', 'VU', '1S'].includes(_vm.trip.source) ? '' : "".concat(fareItem.groupClass)) + " "), _vm.getShowBookingClass ? _c('span', [_vm._v(" ( " + _vm._s(fareItem.bookingClass) + " "), _c('span', {
      staticClass: "h5 text-white"
    }, [_vm._v(" " + _vm._s(fareItem.fareBasisCode) + " ")]), _vm._v(" ) ")]) : _vm._e()])])])], 1)]), _c('b-col', {
      staticClass: "my-75 my-md-0",
      attrs: {
        "cols": "12",
        "lg": "7"
      }
    }, _vm._l(_vm.resolveFarePrice(_vm.flightSearch, fareItem), function (priceItem, indexPrice) {
      return _c('div', {
        key: indexPrice
      }, [_vm.isVisiblePriceFareCol && priceItem.quantity ? _c('b-row', {
        staticClass: "justify-content-between mx-0"
      }, [_c('b-col', {
        staticClass: "text-right px-0",
        attrs: {
          "cols": "auto",
          "md": "3",
          "lg": "2",
          "xl": "3"
        }
      }, [_c('span', [_vm._v(_vm._s(priceItem.title))])]), priceItem.title === 'INF' && (['AK'].includes(_vm.trip.source) || ['TH'].includes(_vm.trip.source) && priceItem.fare === 0) ? _c('b-col', {
        staticClass: "text-danger font-weight-bolder text-right px-0",
        attrs: {
          "cols": "auto",
          "md": "9",
          "lg": "10",
          "xl": "9"
        }
      }, [_vm._v(" Vui lòng vào màn nhập thông tin để kiểm tra giá em bé ")]) : _vm._e(), _vm.isVisiblePriceFareCol && !(priceItem.title === 'INF' && (['AK'].includes(_vm.trip.source) || ['TH'].includes(_vm.trip.source) && priceItem.fare === 0)) ? _c('b-col', {
        staticClass: "text-right px-50 px-lg-0 d-flex flex-column",
        class: _vm.isMobileView ? '' : '',
        attrs: {
          "cols": "auto",
          "md": "3"
        }
      }, [_c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.html.v-primary",
          modifiers: {
            "hover": true,
            "html": true,
            "v-primary": true
          }
        }],
        staticClass: "text-nowrap",
        attrs: {
          "title": "".concat(_vm.$t('flight.netFare'), ": ").concat(_vm.formatCurrency(priceItem.fare), "\n                              ").concat(_vm.getShowPriceAfterDiscount ? "<br>".concat(_vm.$t('flight.promotion'), ": ").concat(_vm.formatCurrency(priceItem.promotion), "<br>\n                              ").concat(_vm.trip.source === '1G' ? _vm.$t('flight.contactBookerForDiscount') : "".concat(_vm.$t('flight.discountAirline'), ": ").concat(_vm.formatCurrency(priceItem.discount))) : '', "\n                              ")
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.priceFareCol)) + " "), priceItem.priceDiscountFareCol > 0 ? _c('span', {
        staticClass: "badge badge-up badge-pill bg-danger badge-glow text-nowrap discount-badge"
      }, [_vm._v(" " + _vm._s("".concat(_vm.numberToFixed(priceItem.priceDiscountFareCol / priceItem.fare * 100), "%")) + " ")]) : _vm._e()]), (priceItem.discount || priceItem.promotion) && _vm.getShowPriceAfterDiscount ? _c('del', {
        staticClass: "text-secondary",
        class: _vm.isMobileView ? 'font-small-2' : 'font-small-4'
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.fare)) + " ")]) : _vm._e()]) : _vm._e(), _vm.isVisibleFeeCol && !(priceItem.title === 'INF' && (['AK'].includes(_vm.trip.source) || ['TH'].includes(_vm.trip.source) && priceItem.fare === 0)) ? _c('b-col', {
        staticClass: "text-right px-0",
        class: _vm.isMobileView ? '' : '',
        attrs: {
          "cols": "auto",
          "md": "3"
        }
      }, [_c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.html.v-primary",
          modifiers: {
            "hover": true,
            "html": true,
            "v-primary": true
          }
        }],
        staticClass: "text-nowrap",
        attrs: {
          "title": "".concat(_vm.$t('flight.taxes'), ": ").concat(_vm.formatCurrency(priceItem.tax + priceItem.surcharge + (!_vm.getShowServiceFee && _vm.getShowPrice !== 'NET_FARE' ? priceItem.serviceFee : 0)), "\n                              ").concat(_vm.getShowServiceFee ? "<br>".concat(_vm.$t('flight.serviceCharge'), ": ").concat(_vm.formatCurrency(priceItem.serviceFee)) : '', "\n                              ").concat(_vm.getShowAgencyFee && priceItem.title !== 'INF' ? "<br>".concat(_vm.$t('flight.agencyFee'), ": ").concat(_vm.formatCurrency(priceItem.agencyFee)) : '', "\n                              ")
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.priceFeeCol)) + " ")])]) : _vm._e(), _vm.isVisibleTotalFareCol && !(priceItem.title === 'INF' && (['AK'].includes(_vm.trip.source) || ['TH'].includes(_vm.trip.source) && priceItem.fare === 0)) ? _c('b-col', {
        staticClass: "text-right px-0 d-flex flex-column",
        class: _vm.isMobileView ? '' : '',
        attrs: {
          "cols": "auto",
          "md": "3"
        }
      }, [_c('HStack', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.v-primary",
          modifiers: {
            "hover": true,
            "v-primary": true
          }
        }],
        class: "text-airline text-nowrap ".concat(priceItem.discount ? 'text-success' : ''),
        attrs: {
          "end": "",
          "title": _vm.$t('flight.Total')
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.priceTotalCol)) + " "), _vm.loadingCalculatePrice && _vm.ticketSelected === fareItem.id ? _c('BSpinner', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.html.v-dark",
          value: 'Đang tính lại giá',
          expression: "'Đang tính lại giá'",
          modifiers: {
            "hover": true,
            "html": true,
            "v-dark": true
          }
        }],
        attrs: {
          "variant": "primary",
          "small": ""
        }
      }) : _vm._e()], 1), (priceItem.discount || priceItem.promotion) && _vm.getShowPriceAfterDiscount ? _c('del', {
        staticClass: "text-secondary",
        class: _vm.isMobileView ? 'font-small-2' : ''
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.priceTotalCol + priceItem.discount + priceItem.promotion)) + " ")]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1);
    }), 0)], 1), _c('FareRulesDetail', {
      attrs: {
        "fare-item": fareItem,
        "trip": _vm.trip
      }
    }), _c('div', {
      staticClass: "position-absolute rounded-pill",
      style: "background-color: white; top: 30%; ".concat(_vm.isMobileView ? "left: -8px;" : "left: -13px;")
    }, [_vm.ticketSelected === fareItem.id && _vm.selectedTrip && _vm.selectedTrip.id === _vm.trip.id ? _c('feather-icon', {
      staticClass: "text-warning",
      attrs: {
        "icon": "CheckCircleIcon",
        "size": _vm.isMobileView ? '20' : '30'
      }
    }) : _c('feather-icon', {
      staticClass: "text-secondary",
      attrs: {
        "icon": "CircleIcon",
        "size": _vm.isMobileView ? '20' : '30'
      }
    })], 1)], 1)], 1);
  }), 0), _vm.isShowAlertContactBookerToCheckPrice1G(_vm.trip) ? _c('b-alert', {
    staticClass: "py-50 px-1 mb-0 text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Vui lòng liên hệ Booker để kiểm tra lại giá vé chuyến bay này! ")]) : _vm._e()], 1) : _vm._e(), _c('b-tab', {
    attrs: {
      "title-link-class": _vm.isMobileView ? 'px-25' : ''
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AlertCircleIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('flight.flightDetails')))])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.trip.segments, function (segment, indexSegment) {
    var _vm$selectedTrip2;
    return _c('div', {
      key: indexSegment
    }, [segment.flightLegInfoList && segment.flightLegInfoList.length > 0 ? _c('div', _vm._l(segment.flightLegInfoList, function (seg, indexSeg) {
      var _vm$selectedTrip;
      return _c('FlightDetail', {
        key: indexSeg,
        class: indexSeg !== 0 ? 'border-top' : '',
        attrs: {
          "segment": Object.assign({}, segment, seg),
          "trip": _vm.trip,
          "index": _vm.index,
          "index-segment": indexSegment,
          "operating": segment.operating,
          "airline": segment.airline,
          "flight-number": segment.flightNumber,
          "selected-trip": ((_vm$selectedTrip = _vm.selectedTrip) === null || _vm$selectedTrip === void 0 ? void 0 : _vm$selectedTrip.id) === _vm.trip.id ? _vm.selectedTrip : null
        }
      });
    }), 1) : _c('FlightDetail', {
      attrs: {
        "segment": segment,
        "trip": _vm.trip,
        "index": _vm.index,
        "index-segment": indexSegment,
        "operating": segment.operating,
        "airline": segment.airline,
        "flight-number": segment.flightNumber,
        "selected-trip": ((_vm$selectedTrip2 = _vm.selectedTrip) === null || _vm$selectedTrip2 === void 0 ? void 0 : _vm$selectedTrip2.id) === _vm.trip.id ? _vm.selectedTrip : null
      }
    }), indexSegment !== _vm.trip.segments.length - 1 ? _c('div', [segment.isNextAirport ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextAirport')))])], 1)], 1) : _vm._e(), segment.isNextDay ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextDay')))])], 1)], 1) : _vm._e(), segment.transitTime ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "primary"
      }
    }, [_c('feather-icon', {
      staticClass: "text-primary mr-1",
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.transitTime')) + ": " + _vm._s(segment.transitTime))])], 1)], 1) : _vm._e(), _c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "primary"
      }
    }, [_c('feather-icon', {
      staticClass: "text-primary mr-1",
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.transitFlightInfo')))])], 1)], 1) : _vm._e()], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }